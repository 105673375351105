import { BookingProductTaxFeeModel } from './bookingproducttaxfee.model';
import { BaseModel } from '../core/_base/crud';
import { CustomerModel } from './customer.model';
import { BookingProductPriceModel } from './bookingproductprice.model';
import { BookingServiceModel } from './bookingservice.model';
import { CustomerInfoModel } from './customerinfo.model';


export class BookingProductSessionModel extends BaseModel {
    bookingProductSessionId: number;
    productId: number;
    bookingId: number;
    productSessionId: number;
    bookingProductStatus: number;
    productName: string;
    fromDate: Date;
    toDate: Date;
    isAllDay: boolean;
    totalAmount: number;
    createdBy: number;
    createdDate: '';
    isAutoConfirmOvertime: boolean;
    isAutoConfirmPayment: boolean;
    waitingTime: number;
    waitingTimeUnit: number;
    tmpCustomerInfo: CustomerInfoModel[];
    tmpOrderInfo: CustomerInfoModel[];
    bookingCustomer: CustomerModel[];
    bookingProductPrice: BookingProductPriceModel[];
    bookingService: BookingServiceModel[];
    bookingProductTaxFees: BookingProductTaxFeeModel[];
    hasHistory: boolean;
    hasPendingRequest: boolean;

    clear() {
        this.bookingProductSessionId = 0;
        this.bookingId = 0;
        this.productId = 0;
        this.productSessionId = 0;
        this.bookingProductStatus = 0;
        this.productName = '';
        this.fromDate = null;
        this.toDate = null;
        this.isAllDay = true;
        this.totalAmount = 0;
        this.createdDate = '';
        this.createdBy = 0;
        this.bookingCustomer = [];
        this.bookingProductPrice = [];
        this.bookingService = [];
        this.tmpCustomerInfo = [];
        this.tmpOrderInfo = [];
        this.isAutoConfirmOvertime = false;
        this.isAutoConfirmPayment = false;
        this.waitingTime = 24;
        this.waitingTimeUnit = 2;
        this.hasHistory = false;
        this.hasPendingRequest = false;
    }
}
