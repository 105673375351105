<!--<div class="kt-login__head">
	<span class="kt-login__signup-label">{{ 'AUTH.GENERAL.NO_ACCOUNT' | translate }}</span>&nbsp;&nbsp;
	<a href="javascript:;" routerLink="/auth/register" class="kt-link kt-login__signup-link">{{ 'AUTH.GENERAL.SIGNUP_BUTTON' | translate }}</a>
</div>-->
<div class="kt-login__body">
	<div class="kt-login__form">
		<div class="kt-login__logo">
			<img alt="logo" src="./assets/media/logos/logo_oxalis_dark.png">
		</div>
		<div class="kt-login__title">
			<h3>{{ 'AUTH.LOGIN.TITLE' | translate }}</h3>
		</div>
		<div class="kt-auth-notice">
			<kt-auth-notice></kt-auth-notice>
		</div>
		<mat-horizontal-stepper linear #stepper>
			<mat-step [stepControl]="loginForm">
				<form class="kt-form" [formGroup]="loginForm" autocomplete="on">
					<div class="form-group">
						<mat-form-field class="mat-form-field-fluid" appearance="outline">
							<mat-label>{{ 'AUTH.INPUT.EMAIL_PLACEHOLDER' | translate }}</mat-label>
							<input matInput id="ipt-oh-email" name="ipt-oh-email" type="email" formControlName="email" autocomplete="on" class="custom-autofill" />
							<mat-error *ngIf="isControlHasError('email','required')">
								{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}
							</mat-error>
							<mat-error *ngIf="isControlHasError('email','email')">
								{{ 'AUTH.VALIDATION.EMAIL_INVALID' | translate }}
							</mat-error>
							<mat-error *ngIf="isControlHasError('email','minlength')">
								{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3
							</mat-error>
							<mat-error *ngIf="isControlHasError('email','maxlength')">
								{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 320
							</mat-error>
							<mat-error *ngIf="isControlHasError('email','serverError')">
								{{ 'AUTH.VALIDATION.INVALID_LOGIN' | translate }}
							</mat-error>
						</mat-form-field>
						<div class="kt-login__actions">
							<button class="btn btn-primary kt-login__btn-primary" (click)="checkemail(stepper)" [ngClass]="{'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading}">{{ 'AUTH.GENERAL.NEXT_BUTTON' | translate }}</button>
						</div>
						<div class="kt-login__signup-label">
							{{ 'AUTH.GENERAL.NO_ACCOUNT' | translate }}
							<a href="javascript:;" routerLink="/auth/register" class="kt-login__signup-link">{{ 'AUTH.GENERAL.SIGNUP_BUTTON' | translate }}</a>
						</div>
					</div>
				</form>
			</mat-step>
			<mat-step>
				<div class="kt-login__otp-label">
					{{ 'AUTH.LOGIN.OTP_NOTICE' | translate }}
				</div>
				<div class="kt-form">
					<div class="form-group">
						<table class="kt-login__otp-input">
							<tr>
								<ng-container *ngFor="let n of otps; let i = index; trackBy:trackByIndex">
									<td>
										<mat-form-field>
											<input id="tab{{i}}" matInput maxlength="1" [(ngModel)]="otps[i]" type="text" autocomplete="off"
												   (keydown)="keyDownEvent($event)"
												   (input)="onInputEntry($event,'tab',(i))"
												   (paste)="onPaste($event, 'tab',(i))" />
										</mat-form-field>
									</td>
								</ng-container>
							</tr>
							<tr *ngIf="otpError">
								<td colspan="6">
									<span class="kt-login__otp-error">
										{{ otpError }}
										<a href="javascript:;" class="kt-link" (click)="retry(stepper)">
											{{ 'AUTH.GENERAL.RETRY' | translate }}
										</a>
									</span>
								</td>
							</tr>
						</table>
						<div class="kt-login__actions">
							<!--<button class="btn btn-default btn-elevate kt-login__btn-primary" matStepperPrevious>{{ 'AUTH.GENERAL.BACK_BUTTON' | translate }}</button>-->
							<!--<button (click)="submit()" [ngClass]="{'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading}" id="kt_login_signin_submit" class="btn btn-primary btn-elevate kt-login__btn-primary">{{ 'AUTH.LOGIN.BUTTON' | translate }}</button>-->
							<button id="kt_login_signin_submit" class="btn btn-primary kt-login__btn-primary" (click)="submit()" [ngClass]="{'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading}">{{ 'AUTH.LOGIN.BUTTON' | translate }}</button>
						</div>
						<div class="kt-login__signup-label">
							{{ 'AUTH.GENERAL.NO_ACCOUNT' | translate }}
							<a href="javascript:;" routerLink="/auth/register" class="kt-login__signup-link">{{ 'AUTH.GENERAL.SIGNUP_BUTTON' | translate }}</a>
						</div>
					</div>
				</div>
			</mat-step>
		</mat-horizontal-stepper>
	</div>
</div>
