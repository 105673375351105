import { BaseModel } from '../core/_base/crud';
import { BookingProductSessionModel } from './bookingproductsession.model';
import { TripModel } from './trip.model';
import { PromotionModel } from './promotion.model';
import { PaymentModel } from './payment.model';
import { CustomerInfoModel } from './customerinfo.model';
import { CustomerModel } from './customer.model';
import { CurrencyModel } from './currency.model';


export class BookingModel extends BaseModel {
    bookingId: number;
    customerId: number;
    bookingNumber: string;
    bookingStatus: number;
    paidStatus: number;
    bookingSource: number;
    createdDate: '';
    createdBy: number;
    updatedDate: '';
    updatedBy: number;
    createdUser: string;
    totalAmount: number;
    totalPayment: number;
    representedBy: number;
    representedUser: string;
    isAutoConfirmOvertime: boolean;
    isAutoConfirmPayment: boolean;
    waitingTime: number;
    waitingTimeUnit: number;
    sendToEmails: string;
    seller: string;
    paidByCurrencyId: number;
    hasMultiCurrency: boolean;
    warningStatus: number;

    customer: CustomerModel;
    bookingProductSession: BookingProductSessionModel[];
    payment: PaymentModel[];
    bookingInTrip: TripModel[];
    bookingPromotion: PromotionModel[];
    paidByCurrency: CurrencyModel;

    clear() {
        this.bookingId = 0;
        this.representedBy = 0;
        this.representedUser = '';
        this.customerId = 0;
        this.bookingNumber = '';
        this.bookingStatus = 0;
        this.paidStatus = 0;
        this.bookingSource = 0;
        this.createdDate = '';
        this.createdBy = 0;
        this.updatedDate = '';
        this.updatedBy = 0;
        this.createdUser = '';
        this.totalAmount = 0;
        this.totalPayment = 0;
        this.paidByCurrencyId = 0;
        this.payment = [];
        this.bookingInTrip = [];
        this.bookingPromotion = [];
        this.bookingProductSession = [];
        this.customer = new CustomerModel();
        this.paidByCurrency = new CurrencyModel();
        this.isAutoConfirmOvertime = false;
        this.isAutoConfirmPayment = false;
        this.waitingTime = 24;
        this.waitingTimeUnit = 2;
        this.sendToEmails = '';
        this.seller = '';
        this.warningStatus = 0;
    }
}
