import { Input, Component, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TypesUtilsService } from '../../../../../core/_base/crud';

@Component({
    selector: 'print-tpl-manifest',
    templateUrl: './manifest.component.html',
    styleUrls: ['./manifest.component.scss']
})

export class PrintTplManifestComponent implements OnChanges {
    @Input() selectedDate: Date;
    @Input() sessionCount: string;
    @Input() customerCount: string;
    @Input() displayCols: string[] = [];
    @Input() dataSource: any[] = [];
    @Input() colList: any[] = [];
    headerRow: string[] = [];
    headerRowIds: string[] = [];
    headerColWidth: number = 0;
    dataRows: any[] = [];
    fromDate: string = '';
    summary: string = '';

    constructor(
        private utils: TypesUtilsService,
        private translate: TranslateService) {

    }
    ngOnChanges(changes: SimpleChanges): void {
        this.headerRow = [];
        this.headerRowIds = [];
        this.dataRows = [];
        this.summary = '';
        this.fromDate = this.utils.dateFormatDDMMYYYY(this.selectedDate);

        this.headerColWidth = 100 / this.displayCols.length;
        this.displayCols.forEach(col => {
            if (col == 'bookingNumber') {
                this.headerRow.push(this.translate.instant("ORDER_TO_CASH.BOOKING.BOOKING_NUMBER"));
            }
            else if (col == 'paidStatus') {
                this.headerRow.push(this.translate.instant("ORDER_TO_CASH.BOOKING.PAID_STATUS"));
            } else {
                this.headerRow.push(col);
                let item = this.colList.filter(f => f.item_name == col);
                if (item) {
                    this.headerRowIds.push(item[0].item_id);
                }
            }
        });
        let i = 1;
        this.dataSource.forEach(m => {
            if (m.isGroup) {
                this.dataRows.push({ isGroup: true, groupName: `${i}. ${this.translate.instant("ENUM.ObjectType.Trip")} ${m.groupName}` });
                this.summary += `${i}. ${m.groupName}</br>`;
                if (m.internalNote.length > 0)
                    this.summary += `&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<em><strong>${this.translate.instant('SCHEDULE.MANIFEST.NOTE')}:</strong> ${m.internalNote}</em></br>`;
                i++;
            } else {
                let dataCells: any[] = [];
                dataCells.push(m.bookingNumber);
                this.headerRowIds.forEach(r => {
                    dataCells.push(m[r]);
                });
                this.dataRows.push(dataCells);
            }
        });
    }
}
