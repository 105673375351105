import { MaytechService } from '../../_maytech/maytech.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpUtilsService, QueryParamsModel, BaseModel, QueryResultsModel } from '../../../core/_base/crud';
import { MaytechTenantService } from '../../_maytech/maytech.tenant.service';
import { ActivatedRoute } from '@angular/router';
import { AppState } from '../../_reducers';
import { Store } from '@ngrx/store';
import { MaytechBaseAction } from '../../_maytech/maytech.actions';
import { HttpAPICallType, ObjectType } from '../../_utils/define';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CustomerInfoModel } from '../../../models/customerinfo.model';
import { ListObjectIDModel } from '../../../models/listobject.model';
import { CustomerModel } from '../../../models/customer.model';
// Real REST API
@Injectable()
export class BookingWarningService extends MaytechService {
    constructor(http: HttpClient,
        httpUtils: HttpUtilsService,
        route: ActivatedRoute,
        tenantService: MaytechTenantService) {
        super(http, httpUtils, route, null, tenantService);
        if (tenantService.currentClient != null)
            this.API_URL = `${tenantService.currentClient.apiurl}/bookingwarning`;
    }

    getBookingCustomerWarningList(bookingId: number): Observable<CustomerModel[]> {
        const url = this.API_URL + "/GetBookingCustomerWarning/" + bookingId.toString();
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        return this.http.get<CustomerModel[]>(url, { headers: httpHeaders }).pipe(catchError(this.handleError("GetBookingCustomerWarning", []))
        );
    }

}
