import { BaseModel } from '../core/_base/crud';


export class BookingForListModel extends BaseModel {
    bookingId: number;
    bookingNumber: string;
    paidStatus: number;
    bookingStatus: number;
    productName: string;
    customerName: string;
    totalAmount: number;
    createdDate: '';
    bookingSource: number;
    createdUser: string;
    representedUser: string;
    phone: string;
    paidByCurrencyId: number;
    warningStatus: number;
    productCode: string;
    product: string;

    clear() {
        this.bookingId = 0;
        this.bookingNumber = '';
        this.paidStatus = 0;
        this.bookingStatus = 0;
        this.productName = '';
        this.customerName = '';
        this.totalAmount = 0;
        this.createdDate = '';
        this.bookingSource = 0;
        this.createdUser = '';
        this.representedUser = '';
        this.phone = '';
        this.paidByCurrencyId = 0;
        this.warningStatus = 0;
    }
}
